/* eslint-disable no-console */
import GreenhouseTranslation from '../../legacy/translation';
import Polyglot, { InterpolationOptions } from 'node-polyglot';

// @ts-expect-error - TS7016 - Could not find a declaration file for module 'pseudo-localization'. 'node_modules/pseudo-localization/lib/index.js' implicitly has an 'any' type.
import { localize as pseudolocalize } from 'pseudo-localization';

type LocalizeOpts = {
  [key: string]: any;
};

type Locale = 'en' | 'de' | 'fr' | 'it' | 'es' | 'pt' | 'ko' | undefined;

const polyglot = new Polyglot({
  onMissingKey: (key: string, opts: InterpolationOptions, locale: string) => {
    // If we add languages that have different translation rules, add a new
    // translation strategy. Right now all we have is the one for English.

    const strategy = translationStrategies.default;
    return strategy(key, opts, locale);
  },
  warn: (message: string) => {
    if (__DEV__) {
      console.error(message);
    }
  },
});

polyglot.extend(GreenhouseTranslation.translations);
// @ts-expect-error - TS2339 - Property 'polyglot' does not exist on type 'Window & typeof globalThis'.
window.polyglot = polyglot;

const translationStrategies = {
  default: (
    key: string,
    opts?: InterpolationOptions,
    locale?: string
  ): string => {
    /*
      If this function is called, we are in one of two states:
        1) The translation is missing and we should call a warn function
        2) A user called t('foo'), where 'foo' does not exist but where
           'foo.one' and 'foo.other' exist and refer to pluralization strings
           Optionally, 'foo.zero' may be used instead of defaulting to 'foo.other'
    */

    const phraseForOne: string | null | undefined =
      polyglot.phrases[`${key}.one`];
    const phraseForOther: string | null | undefined =
      polyglot.phrases[`${key}.other`];
    const phraseForZero: string | null | undefined =
      polyglot.phrases[`${key}.zero`];

    if (phraseForZero && opts && opts.count === 0) {
      return Polyglot.transformPhrase(phraseForZero, opts, locale);
    } else if (phraseForOne && phraseForOther) {
      if (opts && opts.count === 1) {
        return Polyglot.transformPhrase(phraseForOne, opts, locale);
      } else {
        return Polyglot.transformPhrase(phraseForOther, opts, locale);
      }
    } else {
      polyglot.warn(`Missing translation for key: ${key}`);

      return '';
    }
  },
};

const withPhantomLocale = function (translation: string, phrase: string) {
  // @ts-expect-error - TS2339 - Property 'Greenhouse' does not exist on type 'Window & typeof globalThis'.
  const phantomLocale = window.Greenhouse.Translation.phantomLocale;

  if (phantomLocale === 'show_paths') {
    return phrase;
  }

  if (phantomLocale === 'spooky' && translation) {
    translation = pseudolocalize(translation);
  }
  return translation;
};

const tList = (
  key: string,
  params?: LocalizeOpts,
  skipFirstIfEmpty = false
): Array<string> => {
  const phrases: Array<string> = [];
  let i = 0;
  let phrase = polyglot.phrases[`${key}.${i}`];

  const getNextPhrase = (index: number) => polyglot.phrases[`${key}.${index}`];
  // support lists where the first value is empty (e.g. month name translations)
  if (!phrase && skipFirstIfEmpty) {
    phrase = getNextPhrase(++i);
  }

  if (phrase) {
    while (phrase) {
      let translation = Polyglot.transformPhrase(phrase, params);
      translation = withPhantomLocale(translation, phrase);
      phrases.push(translation);
      phrase = getNextPhrase(++i);
    }
  } else {
    polyglot.warn(`Translation key is not a list: ${key}, use t()`);
  }

  return phrases;
};

const t = (phrase: string, params?: LocalizeOpts): string => {
  const translatedString = polyglot.t(phrase, params);

  return withPhantomLocale(translatedString, phrase);
};

const inEnglish = polyglot.t.bind(polyglot);

const withLocalePrefix =
  (localePrefix: string) =>
  (phrase: string, params?: LocalizeOpts): string =>
    t(`${localePrefix}.${phrase}`, params);

const locale = (): Locale => GreenhouseTranslation.locale;

const phantomLocale = (): string | undefined => GreenhouseTranslation.phantomLocale;

const localizeNumber = (number: number): string => {
  // If phantomLocale is spooky, we'll:
  // extend the locale (-u)
  // to add a numbering system (-nu)
  // specifically, mathematical double-struck digits (-mathdbl)
  const postfix = phantomLocale() === 'spooky' ? '-u-nu-mathdbl' : '';
  return number.toLocaleString((locale() ?? 'en') + postfix);
};

export default t;

export {
  polyglot,
  withLocalePrefix,
  inEnglish,
  localizeNumber,
  locale,
  Locale,
  phantomLocale,
  tList,
};
